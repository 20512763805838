// Base stylesheet needed for every page
import './scss/app.scss';

// Lazy load page depended scripts and stylesheets method
const loadComponent = async name => {
    const {default: component} = await import(`./${name}.js`);

    return component();
}

// Import everything in directory method used for example for images
const importAll = (r) => r.keys().forEach(r);

$(document).ready(() => {
    // Include script foreach page example:
    require('../node_modules/cookieconsent/build/cookieconsent.min.js');
    require('../node_modules/bootstrap/js/dist/collapse.js');
    require('./scripts/recaptcha.js');
    require('./scripts/_pde.js');
    require('./scripts/_kiyoh.js');
    require('./scripts/header.js');
    require('./scripts/_table.js');
    require('./scripts/_navigation.js');
    require('./scripts/nav.js');
    require('./scripts/_scroller.js');
    require('./scripts/_microcart.js');
    require('./scripts/cookieconsent.js');
    require('./scripts/geo.js');


    // Include page depended scripts and styles example
    if(document.querySelector('.js-pagebuilder') !== null) {
        loadComponent('pagebuilder');
    }
    if(document.querySelector('.js-category') !== null) {
        loadComponent('category');
    }
    if(document.querySelector('.js-productlist') !== null) {
        loadComponent('productlist');
    }
    if(document.querySelector('.js-productpage') !== null) {
        loadComponent('productpage');
    }
    if(document.querySelector('.js-cart') !== null) {
        loadComponent('cart');
    }
    if(document.querySelector('.js-checkout') !== null) {
        loadComponent('checkout');
    }
    if(document.querySelector('.js-subpage') !== null) {
        loadComponent('subpage');
    }
    if(document.querySelector('.js-customer') !== null) {
        loadComponent('customer');
    }
    if(document.querySelector('.js-customerservice') !== null) {
        loadComponent('customerservice');
    }
    if(document.querySelector('.js-blogpage') !== null) {
        loadComponent('blogpage');
    }
});

// Importing all images
const images = require.context('./images', true,  /\.(png|jpe?g|gif)$/);
importAll(images);

