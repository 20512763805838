import zilvercms from './_utils';

$('.js-nav').each((i, element) => {
    const $nav = $(element);
    const $navItems = $nav.find('.js-nav-item');
    const $navSubItems = $nav.find('.js-nav-subitem');

    const $body = $('body');
    $body.attr('data-loaded', '');

    const setSubNavigationHeight = ($subItem) => {
        const $subItemContainer = $subItem.parents('.js-nav-subitems-container');

        $subItemContainer.removeAttr('style');

        const itemsHeight = $subItemContainer.find('.js-nav-subitems')[0].scrollHeight;
        const subItemsHeight = $subItem.find('.js-nav-subsubitems-container')[0].scrollHeight;

        $subItemContainer.height(itemsHeight > subItemsHeight ? itemsHeight : subItemsHeight);
    };

    if(zilvercms.viewport.isEqualOrGreaterThan('lg')) {

        $navItems.on('mouseenter', e => {
            const $navItem = $(e.currentTarget);

            setSubNavigationHeight($navItem.find('.nav__subitem--active'));

            //zilvercms.lockBody();
            $body.addClass('nav--open');
        });

        $navItems.on('mouseleave', () => {
            $body.removeClass('nav--open');
            //zilvercms.unlockBody();
        });

        $navSubItems.on('mouseenter', (e) => {
            const $navSubItem = $(e.currentTarget);

            $navSubItem.addClass('nav__subitem--active');
            $navSubItem.parents('.js-nav-item').find('.js-nav-subitem').not($navSubItem).removeClass('nav__subitem--active');

            setSubNavigationHeight($navSubItem);
        });
    } else {
        const $navTrigger = $('.js-nav-trigger');
        const $navBack = $('.js-nav-back');
        const $navContainer = $('.js-nav-container');

        let depth = 0;

        $navBack.hide();
        $navItems.removeClass('nav__item--active');
        $navSubItems.removeClass('nav__subitem--active');

        const navMoveNavigation = newDepth => {
            depth = newDepth;

            if(depth === 0) {
                $navBack.hide();
            } else {
                $navBack.show();
            }

            $nav.attr('data-depth', depth);
            $navContainer.css('transform', 'translateX(-'+ depth * 100 +'%)');
        };

        $navTrigger.on('click', () => {
            if($body.hasClass('nav--open')) {
                navMoveNavigation(0);

                $navItems.removeClass('nav__item--active');
                $navSubItems.removeClass('nav__subitem--active');
                $body.removeClass('nav--open');

                zilvercms.unlockBody();
            } else {
                zilvercms.lockBody();
                $body.addClass('nav--open');
            }
        });

        $navBack.on('click', () => {
            let newDepth = depth - 1;

            if(newDepth <= 0) {
                newDepth = 0;
                $navItems.removeClass('nav__item--active');
            }

            if(newDepth === 1) {
                $navSubItems.removeClass('nav__subitem--active');
            }

            navMoveNavigation(newDepth);
        });

        $navItems.children('a').on('click', e => {
            e.preventDefault();

            $(e.currentTarget).parent().addClass('nav__item--active');
            navMoveNavigation(1);
        });

        $navSubItems.children('a').on('click', e => {
            const $navSubItem = $(e.currentTarget).parent();

            if($navSubItem.hasClass('nav__subitem--subs')) {
                e.preventDefault();

                $navSubItem.addClass('nav__subitem--active');
                navMoveNavigation(2);
            }
        });
    }
});
