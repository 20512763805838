import zilvercms from "./_utils";

$('.js-kiyohscore-desktop, .js-kiyohscore-mobile').each(function () {
    var kiyohField = $(this);
    if(zilvercms.getCookie('kiyoh-score')){
        kiyohField.html(zilvercms.getCookie('kiyoh-score'));
        $('.js-kiyoh-amount').html(zilvercms.getCookie('kiyoh-amount'));
    }else {
        $.ajax({
            url:        'https://www.kiyoh.com/v1/publication/review/external/location/statistics?locationId=1043874',
            data:       {signature: '40da513e-7e00-406d-a31e-d4460d975a89'},
            type:       'GET',
            beforeSend: function (xhr) {
                xhr.setRequestHeader('X-Publication-Api-Token', '40da513e-7e00-406d-a31e-d4460d975a89');
            },
            success:    function (data) {
                var score = data.last12MonthAverageRating.toString().replace('.', ',');
                var amount = data.numberReviews;
                kiyohField.html(score);
                $('.js-kiyoh-amount').html(amount);
                zilvercms.setCookie('kiyoh-score', score, 1);
                zilvercms.setCookie('kiyoh-amount', amount, 1);
            }
        });
    }
});

