import zilvercms from "./_utils";

// microcart update event

$('.js-microcart').each(function(){
    var $cart = $(this),
        $toggle = $('.js-microcart-toggle'),
        $inner = $('.js-microcart-inner'),
        $close = $('.js-microcart-close'),
        $nav = $('.navbar-collapse');

    $inner.hide();

    $toggle.on('click', function(){

        $toggle.toggleClass('active');
        $cart.toggleClass('active');

        if($cart.hasClass('active')){
            setTimeout(update_microcart, 400);
        }

        $nav.each(function(){
            $(this).collapse('hide');
        });

        return false;
    });

    $close.on('click', function(){
        $toggle.removeClass('active');
        $cart.removeClass('active');
    });

    update_microcart();
});

function update_microcart(){

    var $cart = $('.js-microcart'),
        $toggle = $('.js-microcart-toggle'),
        $empty = $('.js-microcart-empty'),
        $inner = $('.js-microcart-inner'),
        $products = $('.js-microcart-products');

    // Current product properties, if any
    var $productForm = $('.product-form');
    if($productForm) {
        var currentProductId = parseInt($productForm.find('input[name="productkey"]').val());
        var currentProductMaxCount = $productForm.find('.js-product-count').data('max');
    }

    $.getJSON('/bjax/webshop/Getobjectasjson/cart/', function(data) {
        if(data.itemcount > 0) {
            $empty.hide();
            $cart.removeClass('empty');
            $toggle.removeClass('empty');

            var itemcount = data.itemcount;

            if(itemcount > 8){
                itemcount = 8;
            }
            $('.js-microcart-items-shown').text(itemcount);
            $('.js-microcart-items-total').text(data.itemcount);
            $('.js-microcart-subtotal').html(zilvercms.currencyHelper(data.subtotal));
            if (data.shipping === 0) {
                $('.js-microcart-shipping').addClass("text-success").text(function() {
                    return $(this).data('translation');
                });
            } else {
                $('.js-microcart-shipping').html(zilvercms.currencyHelper(data.shipping));
            }
            $('.js-microcart-total').html(zilvercms.currencyHelper(data.total));


            $products.empty();

            for(var i = 0; i < data.items.length; i++) {
                var itemId = data.items[i].product_id;

                if(typeof currentProductId !== 'undefined' && currentProductId === itemId) {
                    var itemCount = parseInt(data.items[i].count);
                    if(itemCount >= currentProductMaxCount) {
                        $productForm.find('.js-product-count').val(0).attr({
                            'data-min': 0,
                            'data-max': 0
                        });
                        $productForm.find('button[type="submit"]').attr('disabled', 'disabled');
                    } else {
                        $productForm.find('.js-product-count').attr('data-max', (currentProductMaxCount - itemCount));
                    }
                }
                $products.append('<li class="py-2"><a class="d-flex" href="'+data.items[i].product_url+'"><span class="image col-auto d-flex align-items-center justify-content-center"><img src="'+zilvercms.imageHelper(data.items[i].image_url, '74x57,fit')+'" alt="'+data.items[i].name+'"></span><span class="col my-auto pl-0"><span class="title">'+data.items[i].name+'</span><span class="col-12 pl-0 text">'+data.items[i].count+'x</span></span><span class="col-auto my-auto price primary pr-0">'+zilvercms.currencyHelper(data.items[i].price)+'</span></a></li>');

            }

            $products.find('.js-pricetotal').each(function() {
                $(this).html(zilvercms.currencyHelper($(this).html()));
            });

            $.getJSON('/cart?ajax=true', function(result) {
                var button = $('.js-microcart-button');
                if(result.orderable){
                    button.html(zilvercms.translationHelper('cart.your_cart3'));
                }
            });

            $inner.show();
        }else{
            $cart.addClass('empty');
            $inner.hide();
            $empty.show();
            $('.js-microcart-total').html(zilvercms.currencyHelper(0));
        }

        $('.header__microcart-number').attr('data-count', data.itemcount).html(data.itemcount);
        $('.header__microcart-count').attr('data-count', data.itemcount);
        $('.js-microcart-toggle-products').html(zilvercms.currencyHelper(data.total));
    });
}

export default update_microcart;
