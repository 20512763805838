import zilvercms from "./_utils";

$('.js-nav-toggle').on('click', () => {
    var text = $('.js-nav-close-text');
    if(document.body.classList.contains('nav--open')) {
        zilvercms.unlockBody();
        text.html(text.data('open'));
        document.body.classList.remove('nav--open');
        document.getElementsByClassName('header__hamburger')[0].classList.remove('menu-open');
    } else {
        document.body.classList.add('nav--open');
        document.getElementsByClassName('header__hamburger')[0].classList.add('menu-open');
        text.html(text.data('close'));
        zilvercms.lockBody();
    }
});
