const zilvercms = {};

zilvercms.getMetaValue = function(key) {
    const metaTag = document.querySelector('meta[name="zilvercms:'+ key +'"]');

    if(metaTag !== null) {
        return metaTag.getAttribute('content');
    }

    return '';
};

zilvercms.variables = {
    cdnPrefix: zilvercms.getMetaValue('cdnPrefix'),
    currency: {
        showSymbol: !!parseInt(zilvercms.getMetaValue('currencyShowSymbol')),
        symbol: zilvercms.getMetaValue('currencySymbol'),
        leadingSymbol: !!parseInt(zilvercms.getMetaValue('currencyLeadingSymbol')),
        separator: zilvercms.getMetaValue('currencySeparator'),
        ThousandsSeparator: zilvercms.getMetaValue('currencyThousandsSeparator'),
        zerosAsDash: zilvercms.getMetaValue('currencyZerosAsDash')
    },
    sizes: ['xs', 'sm', 'md', 'lg', 'xl', 'xxl']
};

zilvercms.imageHelper = function(url, options, quality) {
    url = !!url ? url : '';
    options = !!options ? options : '100x100';
    quality = !!quality ? quality : 80;

    let prefix = zilvercms.variables.cdnPrefix;
    prefix = prefix.replace('###', options);
    prefix = prefix.replace('QQQ', quality);

    return prefix + url;
};

zilvercms.currencyHelper = function(amount) {
    let value = (amount / 100).toFixed(2);
    const separator = zilvercms.variables.currency.separator;
    const thousendSeparator = zilvercms.variables.currency.ThousandsSeparator;

    value = value.replace('.', separator);

    if(value.length > 6) {
        value = [value.slice(0, -6), thousendSeparator, value.slice(-6)].join('');
    }

    if(zilvercms.variables.currency.showSymbol) {
        var symbol = zilvercms.variables.currency.symbol;

        value = zilvercms.variables.currency.leadingSymbol ? symbol + ' ' + value : value + ' ' + symbol;
    }

    if(zilvercms.variables.currency.zerosAsDash === 1) {
        value = value.replace((separator + '00'), (separator + '-'));
    }

    return value;
};

zilvercms.translationHelper = function(key, variables) {
    if(typeof TRANSLATIONS !== 'undefined' && typeof TRANSLATIONS[key] !== 'undefined') {
        let translation = TRANSLATIONS[key];

        if(typeof variables === 'object') {
            for(var variable in variables) {
                translation = translation.replace(variable, variables[variable]);
            }
        }

        return translation;
    }

    return key;
};

zilvercms.lockBody = function() {
    const $body = $('body'),
        scrollTop = $(window).scrollTop();

    $body.addClass('locked');

    if(scrollTop === 0) {
        $body.addClass('locked--on-top');
    }

    $body.css({ 'position': 'fixed', 'top': (0 - scrollTop), 'width': '100%' }).data('scrollTop', scrollTop);
};

zilvercms.unlockBody = function() {
    const $body = $('body');

    $body.removeClass('locked locked--on-top');

    $body.removeAttr('style');
    $(window).scrollTop($body.data('scrollTop'));
};

zilvercms.viewport = {
    is: function() {
        return window.getComputedStyle(document.body, ':after').content.replace(/"/g, '');
    },
    isEqualOrGreaterThan: function(size) {
        return this.getIndex(this.is()) >= this.getIndex(size);
    },
    isGreaterThan: function(size) {
        return this.getIndex(this.is()) > this.getIndex(size);
    },
    isEqualOrSmallerThan: function(size) {
        return this.getIndex(this.is()) <= this.getIndex(size);
    },
    isSmallerThan: function(size) {
        return this.getIndex(this.is()) < this.getIndex(size);
    },
    getIndex: function(size){
        const number = zilvercms.variables.sizes.indexOf(size);
        if (number === -1) throw 'no valid viewport name given';
        return number;
    }
};

zilvercms.setCookie = function(name, value, days) {
    var d = new Date();
    d.setTime(d.getTime() + (days*24*60*60*1000));
    document.cookie = [name, '=', value, ';expires=', d.toUTCString(), ';path=/'].join('');
}

zilvercms.getCookie = function(name) {
    name = name + '=';
    var decodedCookies = decodeURIComponent(document.cookie);
    var cookies = decodedCookies.split(';');
    for(var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        while(cookie.charAt(0) == ' ') {
            cookie = cookie.substring(1);
        }
        if(cookie.indexOf(name) == 0) {
            return cookie.substring(name.length, cookie.length);
        }
    }
    return '';
}


export default zilvercms;
