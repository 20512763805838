$('.js-header').each((i, element) => {
    const $header = $(element);

    let lastScrollPosition = 0;
    let headerTransform = 0;

    setTimeout(() => {
        $('body').css('padding-top', $header.outerHeight());
    }, 100);

    $(window).on('scroll', () => {
        const windowScrollTop = $(window).scrollTop();
        const headerHeight = $header.outerHeight();

        if(windowScrollTop > lastScrollPosition && lastScrollPosition > 0) {
            if(headerTransform <= headerHeight) {
                headerTransform += windowScrollTop - lastScrollPosition;
            }

            if(headerTransform > headerHeight) {
                headerTransform = headerHeight;
            } else if(headerTransform < 0) {
                headerTransform = 0;
            }

            $header.css('transform', 'translateY(-'+ headerTransform +'px)');
            $header.removeClass('header--show');
        } else {
            if(headerTransform !== 0) {
                $header.addClass('header--transition');
                $header.addClass('header--show');
                $header[0].style.removeProperty('transform');

                headerTransform = 0;

                setTimeout(() => $header.removeClass('header--transition'), 200);
            }
        }

        lastScrollPosition = windowScrollTop;
    });

    $('.js-header-search').on('click', function (){
        var searchbtn = $(this),
            searchField = $('.js-header-searchfield');

        searchbtn.toggleClass('active');
        searchField.parent().toggleClass('d-none');
        searchField.focus();

    });
});

