$('.js-scroller').each(function () {
    var $prev          = $(this).find('.js-scroller-prev'),
        $next          = $(this).find('.js-scroller-next'),
        $scrollElement = $(this).find('.js-row-scroller').children(":first"),
        $scrollChilds  = $scrollElement.find('.js-scrollable'),
        maxScrollLeft  = $scrollElement.prop("scrollWidth") - $scrollElement.width();

    $prev.on('click', function () {
        // prevent scrolling while animating
        if ($(':animated').length) {
            return false;
        }
        $scrollElement.animate({scrollLeft: $scrollElement.scrollLeft() - ($scrollChilds.width() + 30)}, {
            duration: 'slow', complete: function () {
                if ($scrollElement.scrollLeft() <= 0) {
                    $next.removeClass('disabled');
                    $prev.addClass('disabled');
                }
                if ($scrollElement.scrollLeft() < maxScrollLeft) {
                    $next.removeClass('disabled');
                }
            }
        });
    });
    $next.on('click', function () {
        // prevent scrolling while animating
        if ($(':animated').length) {
            return false;
        }
        $scrollElement.animate({scrollLeft: $scrollElement.scrollLeft() + ($scrollChilds.width() + 30)}, {
            duration: 'slow', complete: function () {

                var maxScrollLeft = $scrollElement.prop("scrollWidth") - $scrollElement.width();

                if ($scrollElement.scrollLeft() > 0) {
                    $prev.removeClass('disabled');
                }
                if ($scrollElement.scrollLeft() === maxScrollLeft || $scrollElement.scrollLeft() === maxScrollLeft -1) {
                    $next.addClass('disabled');
                }
            }
        });
    });
});
