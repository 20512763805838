$(document).ready(function() {
    $('.js-cookie-consent').each(function() {
        var $element = $(this),
            data = $element.data('cc');
        window.cookieconsent.initialise({
            'position': data.position,
            'content': {
                'message': data.message,
                'dismiss': data.buttonText,
                'link': data.moreText,
                'href': data.moreLink
            }
        });
        $element.remove();
    });
});